import api from "../services/api";
import { createContext } from "react";

export const MateriaContext = createContext({});

export const MateriaProvider = ({ children }) => {
  const createMateriaApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("create/matery", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const listMateriaApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("list/materies", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const DetailMateriaApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/matery/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const listQuestoesApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get(`list/all/questions`);

    return response;
  };

  const createQuestaoApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("create/question", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const createRespostaQuestaoApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("question/add/answer", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const listRespostasQuestoesApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`matery/${id}/questions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const listMateriaQuestoesApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`matery/${id}/questions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const listAllMateriaQuestoesApi = async (id, difficulty, subject, answer) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      `list/questions/matery/${id}?difficulty=${difficulty}&subject_id=${subject}&answered=${answer}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  };

  const deleteRespostaQuestoesApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/answer/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const deleteMateriaApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/matery/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const deleteQuestionApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/question/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const updateQuestionApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`update/question/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const updateAnswerApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`update/answer/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const listQuestoesDificuldadeApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`list/questions/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const deleteSubjectApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/delete/subject/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const listSubjectsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/list/subjects/matery/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const getQuestionApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/question/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const createSubjectApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/create/subject", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const sendReport = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/report-error", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  return (
    <MateriaContext.Provider
      value={{
        createMateriaApi,
        listMateriaApi,
        DetailMateriaApi,
        listMateriaQuestoesApi,
        createQuestaoApi,
        createRespostaQuestaoApi,
        listRespostasQuestoesApi,
        deleteRespostaQuestoesApi,
        deleteMateriaApi,
        listQuestoesApi,
        deleteQuestionApi,
        updateQuestionApi,
        listAllMateriaQuestoesApi,
        listQuestoesDificuldadeApi,
        createSubjectApi,
        listSubjectsApi,
        deleteSubjectApi,
        getQuestionApi,
        updateAnswerApi,
        sendReport,
      }}
    >
      {children}
    </MateriaContext.Provider>
  );
};
